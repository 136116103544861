<template>
  <div class="floor-map-controls position-relative">
    <div class="floor-svg-container floor-map">
      <svg id="floor_map" width="1237" height="538" viewBox="0 0 1237 538" fill="none" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <image href="@/assets/images/floor-plan.svg" height="538" width="1238" />


        <g v-for="(floor, key) in variants" :key="key">
          <g v-for="(path, pathKey) in floor.paths">
            <path @click="selectFloor(floor)" :id="`${floor.key}-${pathKey}`" :key="`${key}-${pathKey}`" :d="path.path"
              :opacity="isPathSelected(path, floor) ? selectedOpacity : path.opacity" :fill="path.fill"
              :fill-opacity="isPathSelected(path, floor) ? selectedFillOpacity : path.fillOpacity" stroke="#5B5BBA"
              stroke-width="4" v-if="showhighlight" :target="floor.configuration" @mouseover="highlightWing(path);"
              @mouseleave="removehighlight(path)" :title="floor.configuration" :class="{ 'active': path.isActive }" />
            <b-popover triggers="click hover blur" v-if="!isPathSelected(path, floor) && currentScale === 1"
              :target="`${floor.key}-${pathKey}`" placement="top" custom-class="popover-wrapper">
              {{ floor.configuration }}
            </b-popover>
            <b-popover v-if="showMySelectedPopup && isPathSelected(path, floor) && currentScale === 1" :show="true"
              triggers="" :target="`${floor.key}-${pathKey}`" placement="top" custom-class="popover-wrapper">
              {{ floor.configuration }}
            </b-popover>
          </g>
        </g>
      </svg>
    </div>
    <div class="svg-zoom-controls" v-if="!isMobile">
      <button class="btn zoom-button" @click="zoom(1)">
        <img src="@/assets/images/zoom-in.svg" height="16" width="16" class="img-fluid" />
      </button>
      <button class="btn zoom-button zoom-reset-button" @click="reset">Reset</button>
      <button class="btn zoom-button" @click="zoom(-1)">
        <img src="@/assets/images/zoom-out.svg" height="16" width="16" class="img-fluid" />
      </button>
    </div>
  </div>
</template>
<script>
import HoverImage from '../components/Inventory/HoverImage.vue';
import { masterJson } from '@/assets/scripts/utility.js';
import Panzoom from '@panzoom/panzoom';
export default {
  name: "FloorPlanSvg",
  components: {
    HoverImage,
  },
  data() {
    return {
      isHovered: false,
      showhighlight: true,
      selectedOpacity: 0.8,
      selectedFillOpacity: 0.2,
      hoverData: {},
      currentScale: null,
      showMySelectedPopup: false
    }
  },
  mounted() {
    if (!this.isMobile) {
      this.panzoom = Panzoom(document.getElementById('floor_map'), {
        maxScale: 5,
        disablePan: true,
        overflow: "auto"
      });
      this.currentScale = this.panzoom.getScale();
    }
    if (this.selectedFloor && this.selectedFloor.key) {
      setTimeout(() => this.showMySelectedPopup = true, 300);
    }
  },
  watch: {
    selectedFloor() {
      this.showMySelectedPopup = false;
      setTimeout(() => this.showMySelectedPopup = true, 300);
    }
  },
  methods: {
    selectFloor(floor) {
      this.$store.dispatch('setFloor', floor);
    },
    highlightWing(row) {
      row.opacity = this.selectedOpacity;
      row.fillOpacity = this.selectedFillOpacity;
      this.showhighlight = false
      this.showhighlight = true
    },
    removehighlight(row) {
      row.opacity = 0.01;
      row.fillOpacity = 0.02;
      this.showhighlight = false
      this.showhighlight = true
    },
    zoom(level) {
      level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn();
      this.currentScale = this.panzoom.getScale();

    },
    reset() {
      this.panzoom = Panzoom(document.getElementById('floor_map'), {
        scale: 1,
        disablePan: true,
        overflow: "auto"
      });
      this.currentScale = this.panzoom.getScale();
    },
    isPathSelected(path, floor) {
      return this.selectedFloor && this.selectedFloor.key === floor.key;
    }
  },
  computed: {
    variants() {
      return masterJson.Variants;
    },
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedWing() {
      return this.$store.getters.selectedWing;
    },
    isMobile() {
      return window && window.innerWidth <= 991;
    }
  }
}
</script>
<style scoped>
.floor-map path,
.floor-map g {
  outline: none;
  cursor: pointer;
}

.floor-svg-container {
  overflow-x: auto;
  width: 100%;
}

.floor-svg-container {
  scrollbar-width: thin;
}

.floor-svg-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.floor-svg-container::-webkit-scrollbar-track {
  background: transparent
}

.floor-svg-container::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 10px;
  width: 8px;
}

.floor-svg-container::-webkit-scrollbar-thumb:hover {
  background: #5d5e5f;
}


.svg-zoom-controls {
  display: flex;
  justify-content: flex-end;

}

.zoom-button {
  background: #000;
  padding: 8px;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}

.zoom-button:first-child {
  border-radius: 4px 0px 0px 4px;
}

.zoom-button:last-child {
  border-radius: 0px 4px 4px 0px;
}

.zoom-button.zoom-reset-button {
  padding-left: 17px;
  padding-right: 17px;
  background: #444;
  border-radius: 0;
}

#floor_map {
  width: 100%;
  height: 100%;
}

.svg-zoom-controls {
  position: absolute;
  right: 0;
  bottom: 20px;
}

@media (max-width:991px) {

  .floor-svg-container,
  .floor-map-controls,
  .floor-map-section {
    height: 100%;
  }

}
</style>